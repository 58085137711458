export const OnlineSchedulingConstants = {
  siteName: 'onlinescheduling',
  setIsLoading: 'setIsLoading',
  initialLeadData: 'initialLeadData',
  primarySubmission: 'primarySubmission',
  secondarySubmission: 'secondarySubmission',
  tertiarySubmission: 'tertiarySubmission',
  osDomain: 'osDomain',
  osModalId: 'osModal',
  events: {
    onError: 'onError',
    onReady: 'onReady',
    onComplete: 'onComplete',
    onPageChange: 'onPageChange',
    closeModal: 'closeOSModal',
    onSubmitted: 'onSubmitted',
    descriptions: {
      onSubmittedDescription: 'Submitted to CRM',
    },
  },
};
